import mainjson from './main';
import { Level } from '../utils/LoginFunctions';

function checkIf(data) {
    if (parseInt(Level()) <= 0) {
        return true;
    }
    else {
        return false;
    }
}

export default function GetData() {
        let data = {
        "apitype":"locations",
        "id":"location_id",
        "subidname":"location_name",
        "options":["edit","delete","insert"],
        "nameSingle":"location",
        "nameMultiple":"locations",
      
        "fields":{
            "field1":{
                "name":"Name",
                "field":"location_name",
                "type":"TextInput",
                "required":true,
                "list":true
            },
            "field2":{
                "name":"Description",
                "field":"location_description",
                "type":"TextArea",
                "required":true,
                "list":false
            },
            "field3":{
                "name":"Route",
                "field":"location_route",
                "type":"TextArea",
                "required":true,
                "list":false
            },
            "field4":{
                "name":"Website",
                "field":"location_website",
                "type":"TextInput",
                "required":true,
                "list":false
            },
            "field5":{
                "name":"Address",
                "field":"location_address",
                "type":"TextArea",
                "required":true,
                "list":false
            },
            "field6":{
                "name":"Client",
                "field":"location_client_id",
                "type":"DropDown",
                "options": "usergroups",
                "list":true,
                "if":checkIf
            }, 
            


        }
    }

    return data;
}